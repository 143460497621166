// this take the raw book data and processes out only what's needed for the graph

const makegraphData = function(bookData) {
  // console.log('making the graph data!');

  let lines = bookData.lines;
  for (let i = 0; i < lines.length; i++) {
    // console.log(lines[i].name, lines[i].books.length);
    lines[i].id = lines[i].name
      .toLowerCase()
      .replace(/-/g, "")
      .replace(/\./g, "")
      .replace(/&/g, "")
      .replace(/ /g, "");
  }

  const interchangesToDealWith = [];

  for (let i = 0; i < lines.length; i++) {
    for (let j = 0; j < lines[i].books.length; j++) {
      if (lines[i].books[j].interchange) {
        interchangesToDealWith.push({
          title: lines[i].books[j].interchange.title,
          lines: [lines[i].books[j].interchange.line, lines[i].name]
        });
      }
    }
  }

  const isBookAnInterchange = title => {
    for (let i = 0; i < interchangesToDealWith.length; i++) {
      if (interchangesToDealWith[i].title === title) {
        return true;
      }
    }
    return false;
  };

  // console.log(interchangesToDealWith);

  const nodesToBooks = {};

  let graphData = {
    groups: {},
    nodes: [],
    edges: []
  };

  const makeID = (lineID, station) => `${lineID}${station}`;

  const getMyLine = interchange =>
    lines.filter(x => x.name === interchange.line)[0];

  const getMyNumber = interchange => {
    const thisLine = getMyLine(interchange);
    for (let i = 0; i < thisLine.books.length; i++) {
      if (thisLine.books[i].title === interchange.title) {
        return i;
      }
    }
  };

  const isNodeAnInterchange = title =>
    bookData.interchanges.filter(x => x.title === title).length > 0;

  // make all the groups
  // eventually let's define the group here instead of in the data?

  for (let i = 0; i < lines.length; i++) {
    const thisLine = lines[i];
    graphData.groups[thisLine.id] = {
      shape: "box",
      color: thisLine.backgroundColor,
      font: { color: thisLine.textColor },
      title: thisLine.name
    };
    graphData.groups[`${thisLine.id}interchange`] = {
      shape: "star",
      color: thisLine.backgroundColor,
      font: { color: thisLine.textColor },
      borderColor: "black",
      borderWidth: 5,
      padding: 20,
      title: `${thisLine.name} interchange`
    };
  }

  // make the node list

  for (let i = 0; i < lines.length; i++) {
    const thisLine = lines[i];
    for (let j = 0; j < thisLine.books.length; j++) {
      const thisBookTitle = thisLine.books[j].title;
      const thisID = makeID(thisLine.id, j);
      if (thisBookTitle) {
        graphData.nodes.push({
          id: thisID,
          label: thisLine.books[j].titleDisplay || thisBookTitle,
          title: thisLine.name,
          group: isNodeAnInterchange(thisBookTitle)
            ? `${thisLine.id}interchange`
            : thisLine.id,
          widthConstraint: { minimum: 100, maximum: 200 },
          heightConstraint: { minimum: 50, valign: "middle", maximum: 100 }
        });
        nodesToBooks[thisID] = thisLine.books[j];
        nodesToBooks[thisID].id = thisID;
        nodesToBooks[thisID].to = [];
        nodesToBooks[thisID].from = [];
        nodesToBooks[thisID].line = [thisLine.name];
        nodesToBooks[thisID].color = [thisLine.textColor];
        nodesToBooks[thisID].backgroundColor = [thisLine.backgroundColor];
        nodesToBooks[thisID].sequence = [`${j + 1}/${thisLine.books.length}`];
        if (isBookAnInterchange(thisLine.books[j].title)) {
          // add in additional line data if stop is an interchange
          for (let k = 0; k < interchangesToDealWith.length; k++) {
            if (
              interchangesToDealWith[k].title === nodesToBooks[thisID].title
            ) {
              const additionalLineName = interchangesToDealWith[k].lines.filter(
                x => nodesToBooks[thisID].line.indexOf(x) < 0
              )[0];
              if (additionalLineName) {
                nodesToBooks[thisID].line.push(additionalLineName);
                for (let l = 0; l < lines.length; l++) {
                  if (lines[l].name === additionalLineName) {
                    nodesToBooks[thisID].color.push(lines[l].textColor);
                    nodesToBooks[thisID].backgroundColor.push(
                      lines[l].backgroundColor
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  for (let i = 0; i < lines.length; i++) {
    let thisLineID = lines[i].id;
    const defaultColor = lines[i].backgroundColor;
    for (let j = 0; j < lines[i].books.length - 1; j++) {
      let thisNodeID = "";
      let nextNodeID = "";
      if (lines[i].books[j + 1].title) {
        nextNodeID = makeID(thisLineID, j + 1);
        if (lines[i].books[j].title) {
          thisNodeID = makeID(thisLineID, j);
        } else {
          // this is an interchange entry in the line list
          let interchange = lines[i].books[j].interchange;
          thisNodeID = makeID(
            getMyLine(interchange).id,
            getMyNumber(interchange)
          );
        }
      } else {
        // these are nodes that are inserted into a line because of an interchange
        thisNodeID = makeID(thisLineID, j);
        let interchange = lines[i].books[j + 1].interchange;
        nextNodeID = makeID(
          getMyLine(interchange).id,
          getMyNumber(interchange)
        );
      }
      if (thisNodeID) {
        graphData.edges.push({
          from: thisNodeID,
          to: nextNodeID,
          id: `${thisNodeID}-${nextNodeID}`,
          color: {
            color: defaultColor,
            highlight: defaultColor,
            inherit: false
          }
        });
      }
    }
  }

  // add in edges as from: and to: in a later loop

  for (let i = 0; i < graphData.edges.length; i++) {
    nodesToBooks[graphData.edges[i].from].to.push(graphData.edges[i].to);
    nodesToBooks[graphData.edges[i].to].from.push(graphData.edges[i].from);
  }

  // add interchange group to all potential interchanges

  // deal with pedestrian interchanges

  let pedestrianInterchanges = bookData.pedestrianInterchanges;

  for (let i = 0; i < pedestrianInterchanges.length; i++) {
    const interFrom = makeID(
      getMyLine(pedestrianInterchanges[i].from).id,
      getMyNumber(pedestrianInterchanges[i].from)
    );
    const interTo = makeID(
      getMyLine(pedestrianInterchanges[i].to).id,
      getMyNumber(pedestrianInterchanges[i].to)
    );
    graphData.edges.push({
      from: interFrom,
      to: interTo,
      arrows: { to: false, from: false },
      dashes: [20, 40],
      width: 20,
      id: `pedestrianinterchange${i}`,
      color: { color: "gray", highlight: "gray" }
    });
    // TODO: Should these be dealt with by the popupwindow? Right now they're not. They'd have to edit what's in nodestobooks
  }
  // let output = [];
  // for (let i in nodesToBooks) {
  //   output.push({ id: nodesToBooks[i].id, title: nodesToBooks[i].title });
  // }
  // console.log(JSON.stringify(output));
  const colorData = {};
  for (let i = 0; i < lines.length; i++) {
    colorData[lines[i].name] = {
      color: lines[i].textColor,
      backgroundColor: lines[i].backgroundColor,
      image: lines[i].image
    };
  }
  return { graphData, nodesToBooks, colorData };
};

export default makegraphData;
