// interchanges work like this:
//
// 				{ interchange: { line: 'Animal Line', title: 'Just So Stories' } },
//
// (that's for the second line, first line is just like that)
// (two interchanges in a row make it unhappy for some reason)
//
// possible interchanges are commented out!

import AdventureImage from "../images/adventure.svg";
import AnimalImage from "../images/animal.svg";
import ChildhoodImage from "../images/childhood.svg";
import FantasyImage from "../images/fantasy.svg";
import HorrorImage from "../images/horror.svg";
import PoetryImage from "../images/poetry.svg";
import RealLifeImage from "../images/reallife.svg";
import SciFiImage from "../images/scifi.svg";

const bookData = {
  lines: [
    {
      name: "Fantasy & Magic Line",
      textColor: "#000000",
      backgroundColor: "#F2C94C",
      image: FantasyImage,
      books: [
        {
          title: "Rip Van Winkle",
          date: 1819,
          author: "Washington Irving",
          cover: "ripvanwinkle.png",
          description: `Rip van Winkle is an amiable man whose home and farm suffer from his lazy neglect; a familiar figure about the village, he is loved by all except his wife. One autumn day he wanders up into the mountains, drinks some mysterious  liquor and falls asleep. He wakes up twenty years later and has slept through the American Revolution.`
        },
        {
          title: "Tales of Hans Christian Andersen",
          titleDisplay: "Hans Christian Andersen",
          date: 1875,
          author: "Hans Christian Andersen",
          cover: "hanschristianandersen.jpg",
          description: `Anderson’s fairy tales include: <em>The Emperor's New Clothes</em>, <em>The Little Mermaid</em>, <em>The Ugly Duckling</em>, <em>The Princess and the Pea</em> and other enchanting stories.`
        },
        {
          title: "The Water Babies",
          date: 1863,
          author: "Charles Kingsley",
          cover: "thewaterbabies.png",
          description: `In the underwater adventures of Tom, a chimney-sweeper's boy who is transformed into a water-baby after he drowns, Charles Kingsley combines comic fantasy and moral fable to create an extraordinary effect. It is part fairytale and part a satire of Darwin’s <em>The Origin of the Species</em>.`
        },
        {
          title: "The Princess and the Goblin",
          titleDisplay: "The Princess\nand the Goblin",
          date: 1872,
          author: "George MacDonald",
          cover: "theprincessandthegoblin.png",
          description: `Princess Irene lives in a castle in a wild and lonely mountainous region. One day she discovers a steep and winding stairway leading to a bewildering labyrinth of unused passages with closed doors - and a further stairway. But what lies at the top? `
        },
        {
          title: "The Happy Prince",
          author: "Oscar Wilde",
          date: 1888,
          cover: "thehappyprince.jpg",
          description: `The much-loved story of a gilded statue, a kind-hearted swallow, and generosity of spirit. A swallow bound for Egypt takes refuge at the feet of a golden statue of a prince erected in a European town, agreeing to bring pieces of the statue to less fortunate city dwellers at the request of the prince himself.`
        },
        {
          title: "Five Children and It",
          titleDisplay: "Five Children\nand It",
          author: "Edith Nesbit",
          date: 1902,
          cover: "fivechildrenandit.jpg",
          description: `When Cyril, Anthea, Robert, Jane and their baby brother go digging in the gravel pit, the last thing they expect to find is a Psammead – an ancient Sand-fairy! Having a Sand-fairy for a pet means having one wish granted each day. But the children don't realize all the trouble wishes can cause.`
        },
        {
          title: "The Phoenix and the Carpet",
          titleDisplay: "The Phoenix\nand the Carpet",
          author: "Edith Nesbit",
          date: 1904,
          cover: "thephoenixandthecarpet.jpg",
          description: `The sequel to <em>Five Children and It</em>: Mother buys the children a new carpet to replace one from the nursery that they have destroyed in an accidental fire. The children find an egg in the carpet, which hatches into a talking Phoenix. The Phoenix explains that the carpet is a magic one that will grant them three wishes a day. The adventures are continued and concluded in the third book of the trilogy, <em>The Story of the Amulet</em> (1906).`
        },
        {
          title: "The Box of Delights",
          author: "John Masefield",
          date: 1935,
          cover: "theboxofdelights.jpg",
          description: `Strange things begin to happen the minute young Kay Harker boards the train to go home for Christmas and finds himself under observation by two very shifty-looking characters. Arriving at his destination, the boy is immediately accosted by a bright-eyed old man who gives him a magical box with a mysterious message: “The wolves are running.” `
        },
        {
          title: "The Indian in the Cupboard",
          titleDisplay: "The Indian in\nthe Cupboard",
          author: "Lynne Reid Banks",
          date: 1980,
          cover: "theindianinthecupboard.jpg",
          description: `For Omri, it is a dream come true when the plastic American Indian he locks into the old cupboard comes to life. Little Bull is everything an Indian brave should be – proud, fearless and defiant.  But being in charge of a real, live, human being is a heavy responsibility.`
        },
        {
          title: "The Story of Doctor Dolittle",
          titleDisplay: "The Story of\nDoctor Dolittle",
          date: 1920,
          author: "Hugh Lofting",
          cover: "thestoryofdoctordolittle.jpg",
          description: `In this first book in the series, Doctor Dolittle discovers that he can talk to the animals–Jip the dog, Dab Dab the duck, and Polynesia the parrot.`
        },
        {
          interchange: {
            title: "Alice’s Adventures in Wonderland",
            line: "Childhood Line"
          }
        },
        {
          title: "The Little Prince",
          author: "Antoine De Saint-Exupery",
          date: 1943,
          cover: "thelittleprince.png",
          description: `<em>The Little Prince</em> journeys to our planet from his home among the stars, encountering all sorts of creatures along the way, and a fox, who teaches him how to see the important things in life.`
        },
        {
          title: "The Hobbit",
          author: "J. R. R. Tolkien",
          date: 1937,
          cover: "thehobbit.jpg",
          description: `The adventure follows Bilbo Baggins, who is swept into an epic quest to reclaim Erebor with the help of Gandalf the Grey and the thirteen dwarves led by the legendary warrior, Thorin Oakenshield. `
        },
        {
          title: "The Little White Horse",
          author: "Elizabeth Goudge",
          date: 1946,
          cover: "thelittlewhitehorse.png",
          description: `When orphaned young Maria Merryweather arrives at Moonacre Manor, she feels as if she's entered paradise. But there is something incredibly sad beneath all of this beauty and comfort--a tragedy that happened years ago, shadowing Moonacre Manor and the town around it.`
        },
        {
          title: "The Lion, the Witch and the Wardrobe",
          titleDisplay: "The Lion, the Witch . . .",
          author: "C. S. Lewis",
          date: 1950,
          cover: "thelionthewitchandthewardrobe.png",
          description: `During the World War II bombings of London, four English siblings are evacuated to a country house where they will be safe. The children find a wardrobe that transports them to a magical world called Narnia. There they join the magical lion, Aslan, in the fight against the evil White Witch, Jadis.`
        },
        {
          title: "The Borrowers",
          author: "Mary Norton",
          date: 1952,
          cover: "theborrowers.png",
          description: `Arietty Clock isn't your typical little girl as she is very little. She's a Borrower! She lives with her family in a large house that isn't technically theirs - it belongs to the 'human beans'. Arietty and her father, Pod, spend their days borrowing the human beans' possessions until Arietty makes friends with a human bean boy and this brings danger. `
        },
        {
          title: "The Weirdstone of Brisingamen",
          titleDisplay: "The Weirdstone \nof Brisingamen",
          author: "Alan Garner",
          date: 1957,
          cover: "theweirdstoneofbrisingamen.png",
          description: `Colin and Susan are on a desperate search to locate and return the Weirdstone, a magic stone that will wake the sleeping knights, to their homeland before Evil takes over, but an army of wicked creatures is making things extremely difficult for them.`
        },
        {
          title: "Stig of the Dump",
          author: "Clive King",
          date: 1963,
          cover: "stigofthedump.png",
          description: `Barney goes down the chalk pit, where everyone tells him not to go and he finds a boy; but not just an ordinary boy, a caveman named Stig. The story is all about how Stig and Barney's friendship blossoms with a few bumps along the way.`
        },
        {
          title: "The Ogre Downstairs",
          author: "Diana Wynne Jones",
          date: 1974,
          cover: "theogredownstairs.png",
          description: `Casper, Johnny and Gwinny get a big shock when their mother marries the Ogre. The Ogre is large and stern and adds his own two awful sons, Douglas and Malcolm, to the family mix. This can lead to only one thing - war!`
        },
        {
          title: "A Pack of Lies",
          autho: "Geraldine McCaughrean",
          date: 1988,
          cover: "apackoflies.jpg",
          description: `Ailsa doesn't trust MCC Berkshire, the mysterious man helping out in her mother's antique shop. He tells wonderful stories about all the antiques, but everything he says is a pack of lies, isn't it? `
        }
      ]
    },
    {
      name: "Animal Line",
      textColor: "#ffffff",
      backgroundColor: "#FF4747",
      image: AnimalImage,
      books: [
        {
          title: "The Jungle Book",
          author: "Rudyard Kipling",
          date: 1894,
          cover: "thejunglebook.png",
          description: `Orphaned as a baby, human-boy Mowgli is adopted by wolves, befriended by Baloo the bear, and educated in the wonders and dangers of the Indian jungle. The story is told through the tales of Kotick the White Seal, Rikki-tikki-tavi the mongoose, and other animals.`
        },
        {
          title: "Just So Stories",
          author: "Rudyard Kipling",
          date: 1902,
          cover: "justsostories.png",
          description: `How did the rude Rhinoceros get his baggy skin? How did a curious Elephant change the lives of his family evermore? First told aloud to his young daughter, Rudyard Kipling's inspired answers to these and other burning questions draw from the fables he heard as a child in India and the folktales he gathered from around the world.`
        },
        {
          title: "The Call of the Wild",
          titleDisplay: "The Call\nof the Wild",
          date: 1903,
          author: "Jack London",
          cover: "thecallofthewild.png",
          description: `This story is set in the Yukon, Canada, during the 1890s Klondike Gold Rush—a period in which strong sled dogs were in high demand. The novel's central character is a dog named Buck, a domesticated dog who is stolen from his home and sold into service as a sled dog in Alaska.`
        },
        {
          title: "The Wind in the Willows",
          titleDisplay: "The Wind in\nthe Willows",
          date: 1909,
          author: "Kenneth Grahame",
          cover: "thewindinthewillows.png",
          description: `Follow the lives of four friends who live on the riverbank: Mole, Ratty, Toad and Badger over one whole year. They spend idyllic lazy days messing about on the river and have daring adventures too, especially in the Wild Woods where the villainous weasels live and are to be avoided at all costs. Their stories are about adventure, friendship and honesty.`
        },
        {
          title: "Black Beauty",
          author: "Anna Sewall",
          date: 1877,
          cover: "blackbeauty.png",
          description: `Black Beauty is a handsome, sweet-tempered colt with a strong spirit. But when his owners are forced to sell him, Black Beauty goes from a life of comfort and kindness to one of hard labour and cruelty. But Black Beauty has an unbreakable spirit and will, and is determined to survive.`
        },
        {
          interchange: {
            title: "The Story of Doctor Dolittle",
            line: "Fantasy & Magic Line"
          }
        },
        {
          title: "Tarka the Otter",
          author: "Henry Williamson",
          date: 1927,
          cover: "tarkatheotter.png",
          description: `Tarka the Otter is the classic story of an otter living in the Devonshire countryside which captures the feel of life in the wild as seen through the otter's own eyes.`
        },

        {
          title: "101 Dalmatians",
          author: "Dodie Smith",
          date: 1956,
          cover: "the101dalmations.png",
          description: `When Dearly's Dalmatians have their first litter of pups—fifteen in all—everyone is delighted. But their joy is short lived for the pups are kidnapped! Scotland Yard is baffled, but the keenest canine minds are on the case—and on the trail of Cruella de Vil, the most fiendish person to ever covet a fur coat.`
        },
        {
          title: "A Bear Called Paddington",
          titleDisplay: "A Bear Called\nPaddington",
          date: 1958,
          author: "Michael Bond",
          cover: "abearcalledpaddington.jpg",
          description: `Paddington Bear had travelled all the way from Darkest Peru when the Brown family first met him on Paddington station. Since then their lives have never been quite the same… for ordinary things become quite extraordinary when a bear called Paddington is involved.`
        },
        {
          interchange: {
            title: "Where the Red Fern Grows",
            line: "Adventure Line"
          }
        },
        {
          title: "The Butterfly Lion",
          author: "Michael Morpurgo",
          date: 1996,
          cover: "thebutterflylion.jpg",
          description: `Bertie rescues an orphaned white lion cub from the African veld. They are inseparable until Bertie is sent to boarding school far away in England and the lion is sold to a circus. Bertie swears that one day they will see one another again, but it is the butterfly lion which ensures that their friendship will never be forgotten.`
        },
        {
          title: "A Dog So Small",
          date: 1962,
          author: "Philippa Pearce",
          cover: "adogsosmall.png",
          description: `Young Ben Blewitt is desperate for a dog. He's picked out the biggest and best dogs from the books in the library - and he just knows he's going to get one for his birthday. Ben is excited when the big day arrives, but he receives a picture of a dog instead of a real one! But the imagination can be a powerful thing, and when Ben puts his to work, his adventures really begin!`
        },
        {
          title: "How the Whale Became and Other Stories",
          titleDisplay: "How the\nWhale Became",
          author: "Ted Hughes",
          date: 1963,
          cover: "howthewhalebecame.jpg",
          description: `Eleven wonderful creation stories including: <em>The Whale who grew up in God's vegetable patch</em> but was banished to sea when he became too large and crushed all His carrots; and <em>The Polar Bear who was lured to the North Pole</em> by the other animals who were jealous that she always won the annual beauty contest. `
        },
        {
          title: "Watership Down",
          author: "Richard Adams",
          date: 1972,
          cover: "watershipdown.png",
          description: `Set in England's countryside, this stirring tale of adventure, courage and survival follows a group of wild rabbits on their flight from the intrusion of man and the certain destruction of their home at Sandleford Warren. They face harrowing trials posed by predators and enemies, journeying together to the mysterious Watership Down. `
        }
      ]
    },

    {
      name: "Adventure Line",
      textColor: "#000000",
      backgroundColor: "#39CFFF",
      image: AdventureImage,
      books: [
        {
          title: "Gulliver’s Travels",
          author: "Jonathan Swift",
          date: 1726,
          cover: "gulliverstravels.jpg",
          description: `Gulliver has an itch to travel around the world, but whenever he steps on a ship, bad luck seems to find him. He is shipwrecked, abandoned, marooned, and mutinied against, and each time lands in a strange and curious place.`
        },
        {
          title: "The Swiss Family Robinson",
          titleDisplay: "The Swiss Family\nRobinson",
          date: 1812,
          author: "Johann David Wyss",
          cover: "theswissfamilyrobinson.png",
          description: `This is the story of a Swiss family shipwrecked in South East Asia. It teaches the values of resourcefulness, resilience and family values. It is based on the novel for adults, <em>Robinson Crusoe</em>, written by Daniel Defoe in 1719.`
        },
        {
          title: "The Coral Island",
          author: "R. M. Ballantyne",
          date: 1858,
          cover: "thecoralisland.jpg",
          description: `Ralph and his two friends survive a treacherous storm in the middle of the Pacific and land on a coral reef with only a broken knife and telescope.  They succeed in living off the land and are happy until cannibals and a terrible pirate arrive.`
        },
        {
          title: "Our Island Story: A Child’s History of England",
          titleDisplay: "Our Island Story",
          author: "Henrietta Elizabeth Marshall",
          date: 1905,
          cover: "ourislandstory.jpg",
          description: `A child's history of England from earliest legendary times.  Beginning with the stories of Albion and Brutus, it relates all the interesting legends and heroic tales of England through to the end of the reign of Queen Victoria.`
        },
        {
          title: "Tales from 1001 Nights",
          titleDisplay: "Tales from\n1001 Nights",
          author: "various authors",
          date: "8th–14th centuries",
          cover: "thearabiannights.jpg",
          description: `A wonderful collection of stories from the Islamic golden age. It includes the stories: <em>Aladdin and his Magic Lamp</em>, along with <em>Ali Baba and the Forty Thieves</em>, and <em>The Seven Voyages of Sinbad the Sailor</em>.`
        },
        {
          title: "Swallows and Amazons",
          author: "Arthur Ransome",
          date: 1929,
          cover: "swallowsandamazons.png",
          description: `For the Walker children, sailing the boat <em>Swallow</em> to an island for a camping trip is a fantastic adventure. But soon they find themselves under attack from the fierce pirates of the Amazon, Nancy and Peggy. And so begins the battles, alliances and discoveries in a summer like no other.`
        },
        {
          title: "Emil and the Detectives",
          titleDisplay: "Emil and the\nDetectives",
          author: `Erich Kästner`,
          date: 1929,
          cover: "emilandthedetectives.png",
          description: `Young Emil is robbed on his first real railway journey, then a gang of boys about his own age come to his aid. This  thrilling adventure is full of surprises as the boys devise a way to capture the thief.`
        },
        {
          title: "The Three Musketeers",
          author: "Alexandre Dumas",
          date: 1844,
          cover: "thethreemusketeers.png",
          description: `Set in pre-revolutionary France, our protagonist, D'Artagnan, travels to Paris with his future sealed in an envelope. When the letter is stolen by a cloaked figure, D'Artagnan joins the company of the King, where he meets Porthos, Aramis and Athos - The Three Musketeers!`
        },
        {
          interchange: {
            title: "The Adventures of Robin Hood",
            line: "Real Life Line"
          }
        },
        {
          title: "Where the Red Fern Grows",
          titleDisplay: "Where the\nRed Fern Grows",
          date: 1961,
          author: "Wilson Rawls",
          cover: "wheretheredferngrows.png",
          description: `This is the adventurous story of a young boy and his dream for his own red-bone hound hunting dogs. Set in the Ozark Mountains during the Great Depression, Billy Coleman works hard and saves his earnings for two years to achieve his dream of buying two coonhound pups.`
        },
        {
          title: "Pippi Longstocking",
          author: "Astrid Lindgren",
          date: 1945,
          cover: "pippilongstocking.png",
          description: `Pippi Longstocking is nine years old. She has just moved into Villa Villekulla where she lives all by herself with a horse, a monkey, and a big suitcase full of gold coins. The grown-ups in the village try to make Pippi behave in ways that they think a little girl should, but Pippi has other ideas! `
        },
        {
          title: "King Arthur and the Knights of the Round Table",
          titleDisplay: "King Arthur",
          date: 1953,
          author: "Roger Lancelyn Green",
          cover: "kingarthur.png",
          description: `King Arthur is one of the greatest legends of all time: from the magical moment when Arthur releases the sword in the stone, to the quest for the Holy Grail, and the final tragedy of the Last Battle.`
        },
        {
          title: "Tom’s Midnight Garden",
          author: "Philippa Pearce",
          date: 1958,
          cover: "tomsmidnightgarden.jpg",
          description: `A magical garden that only appears at midnight, full of children who just might be ghosts. From the clock striking thirteen to its impossible moonlit lawns, this haunting classic will give you the dreamiest kind of shivers. `
        }
      ]
    },
    {
      name: "Childhood Line",
      textColor: "#ffffff",
      backgroundColor: "#27A95B",
      image: ChildhoodImage,
      books: [
        {
          title: "Oliver Twist",
          author: "Charles Dickens",
          date: 1837,
          cover: "olivertwist.png",
          description: `Orphan, Oliver, escapes the workhouse and falls into the company of the Artful Dodger, as roistering and swaggering a young gentleman as ever stood at four foot six. They live in the dirty back streets of London amidst a brood of thieves and pickpockets.`
        },
        {
          title: "Little Women",
          author: "Louisa May Alcott",
          date: 1838,
          cover: "littlewomen.png",
          description: `Meet the March Sisters:  Meg is on the brink of love; tomboy Jo longs to be a writer; sweet-natured Beth always puts others first; and Amy is the youngest and most precocious. Money is short and their father is fighting in the American Civil War, but their infectious sense of fun sweeps everyone up in their adventures - including Laurie, the boy next door.`
        },
        {
          title: "What Katy Did",
          author: "Susan Coolidge",
          date: 1872,
          cover: "whatkatydid.png",
          description: `Katy Carr intends to be beautiful and beloved one day. For now, she doesn't care at all for being called 'good'. But then a terrible accident happens and Katy must find the courage to remember her daydreams and the delightful plans she once schemed.`
        },
        {
          title: "The Ghost of Thomas Kempe",
          titleDisplay: "The Ghost of\nThomas Kempe",
          author: "Penelope Lively",
          date: 1973,
          cover: "theghostofthomaskempe.png",
          description: `James is fed up. His family has moved to a new cottage - with grounds that are great for excavations, and trees that are perfect for climbing - and stuff is happening.  But it's not him who's writing strange things on shopping lists and fences.`
        },
        {
          title: "The Adventures of Tom Sawyer",
          titleDisplay: "The Adventures\nof Tom Sawyer",
          author: "Mark Twain",
          date: 1876,
          cover: "theadventuresoftomsawyer.png",
          description: `Tom Sawyer and his friends seek out adventure at every turn. Then one fateful night they witness a murder. The boys swear never to reveal the secret and run away to be pirates and search for hidden treasure.`
        },
        {
          title: "Heidi",
          author: "Johanna Spyri",
          date: 1879,
          cover: "heidi.png",
          description: `When Heidi is sent to live in the Swiss mountains with her bad-tempered old Grandfather, everyone in the village feels sorry for her. But Heidi discovers that Grandfather isn't nearly as cross as he looks - plus she can play all day in the fields amongst the bright flowers.`
        },
        {
          interchange: {
            title: "Tales from 1001 Nights",
            line: "Adventure Line"
          }
        },
        {
          title: "Little Lord Fauntleroy",
          author: "Frances Hodgson Burnett",
          date: 1885,
          cover: "littlelordfauntleroy.png",
          description: `He is a small boy from New York who is told he is the heir to an English earldom and is whisked away to the English countryside where he begins to win over his bad-tempered old grandfather.`
        },
        {
          title: "Peter Pan",
          author: "J. M. Barrie",
          date: 1904,
          cover: "peterpan.png",
          description: `Over the rooftops of London, Peter Pan and the fairy Tinkerbell lead Wendy, Michael and John Darling to Neverland to start a new life with his gang of Lost Boys. There, they will encounter the fearsome Captain Hook and his terrible crew of pirates.`
        },
        { interchange: { title: "Black Beauty", line: "Animal Line" } },
        {
          title: "Alice’s Adventures in Wonderland",
          titleDisplay: "Alice in Wonderland",
          date: 1865,
          author: "Lewis Caroll",
          cover: "alicesadventuresinwonderland.jpg",
          description: `A girl named Alice falls through a rabbit hole into a fantasy world populated by peculiar, anthropomorphic creatures. The tale plays with logic and it is considered to be one of the best examples of the literary nonsense genre.`
        },
        {
          title: "A Little Princess",
          author: "Frances Hodgson Burnett",
          date: 1905,
          cover: "alittleprincess.png",
          description: `When wealthy Sara Crewe arrives at Miss Minchin's boarding school in London, she is determined to settle in and make friends.`
        },
        {
          interchange: {
            title: "The Children of Green Knowe",
            line: "Scary and Horror Line"
          }
        },
        {
          title: "The Railway Children",
          author: `Edith Nesbit`,
          date: 1905,
          cover: "therailwaychildren.png",
          description: `They were not railway children to begin with. When their Father mysteriously leaves home Roberta (Bobbie), Phyllis and Peter must move to a small cottage in the countryside with Mother. It is a bitter blow to leave their London home, but soon they discover the hills and valleys, the canal and of course, the railway.`
        },
        {
          title: "Anne of Green Gables",
          author: "Lucy Maud Montgomery",
          date: 1905,
          cover: "anneofgreengables.png",
          description: `Marilla and Matthew Cuthbert are in for a big surprise. They are waiting for an orphan boy to help with the work at Green Gables - but a red-haired girl turns up instead. Anne Shirley charms her way into the Cuthberts' affection with her vivid imagination and constant chatter.`
        },
        { interchange: { title: "The Butterfly Lion", line: "Animal Line" } },
        {
          title: "The Secret Garden",
          author: "Frances Hodgson Burnett",
          date: 1911,
          cover: "thesecretgarden.png",
          description: `After the death of her parents, Mary Lennox is sent back from India to live in her uncle's huge, gloomy house on the English moors. Mary is lonely and miserable until she stumbles upon her disabled cousin Colin, hidden away from the world by his troubled father. `
        },
        {
          title: "Pollyanna",
          author: "Eleanor H. Porter",
          date: 1913,
          cover: "pollyanna.png",
          description: `As soon as Pollyanna arrives in Beldingsville to live with her strict and dutiful maiden aunt, she begins to brighten up everybody's life. `
        },
        {
          title: "Mary Poppins",
          author: "P. L. Travers",
          date: 1934,
          cover: "marypoppins.png",
          description: `When Mary Poppins arrives at their house on a gust of the East Wind and slides up the bannister, Jane and Michael Banks’s lives are turned magically upside down.`
        },
        {
          title: "Ballet Shoes",
          author: "Noel Streatfeild",
          date: 1936,
          cover: "balletshoes.png",
          description: `Pauline longs to be an actress, Petrova is happiest playing with cars and engines, and if she could . . . Posy would dance all day! But when their benefactor Great-Uncle Matthew disappears, the Fossil girls share a future of a dazzling life on stage, where their dreams and fears will soon come true. `
        },
        {
          title: "A Child’s Christmas in Wales",
          author: "Dylan Thomas",
          date: 1952,
          cover: "achildschristmasinwales.png",
          description: `The beauty and tradition of Christmas at every turn: the warmth of a family gathering; the loveliness of a mistletoe-decked home; the predictability of cats by the fire; the mischief and fun of children left to their own devices; and the sheer delight of gifts - be they useful or useless.`
        },
        { interchange: { title: "Oliver Twist", line: "Childhood Line" } }
      ]
    },
    {
      name: "Sci-Fi Line",
      textColor: "#ffffff",
      backgroundColor: "#0B39AE",
      image: SciFiImage,
      books: [
        {
          title: "Journey to the Centre of the Earth",
          author: "Jules Verne",
          date: 1864,
          cover: "journeytothecentreoftheearth.png",
          description: `After decoding a scrap of paper in runic script, the intrepid Professor Lidenbrock and his nervous nephew Axel travel across Iceland to find the secret passage to the centre of the earth.`
        },
        {
          title: "20,000 Leagues under the Sea",
          author: "Jules Verne",
          date: 1870,
          cover: "twentythousandleaguesunderthesea.png",
          description: `Professor Aronnax embarks on an expedition to hunt down and destroy a menacing sea monster. However, he discovers that the beast is metal - it is a giant submarine called the Nautilus built by the renegade scientist Captain Nemo. So begins an underwater journey to the lost city of Atlantis.`
        },
        {
          interchange: {
            title: "The Little Prince",
            line: "Fantasy & Magic Line"
          }
        },
        {
          title: "The Wizard of Oz",
          author: "L. Frank Baum",
          date: 1900,
          cover: "thewizardofoz.png",
          description: `When a tornado crashes through Kansas City, Dorothy and her dog Toto are whisked far away, over the rainbow, to a strange land called Oz. How will they ever get home? And what is at the end of the yellow brick road?`
        },
        {
          title: "The First Men in the Moon",
          date: 1901,
          author: "H. G. Wells",
          cover: "thefirstmeninthemoon.png",
          description: `Thanks to the discovery of an anti-gravity metal, Cavorite, two Victorian Englishman tackle the most prestigious goal - space travel. They construct a sphere that will ultimately take them to the moon. On landing, they encounter what seems like an utterly barren landscape but they soon find signs that the planet is very much alive.`
        },
        {
          title: "A Wrinkle in Time",
          date: 1962,
          author: "Madeleine L’Engle",
          cover: "awrinkleintime.png",
          description: `Meg always felt she was different and when she and her little brother, Charles Murry, go searching for their lost father, they find themselves travelling on a dangerous journey through a <em>wrinkle in time</em>.`
        },
        {
          title: "The Iron Man",
          author: "Ted Hughes",
          date: 1968,
          cover: "theironman.jpg",
          description: `At first, the Iron Man wreaks dreadful destruction. He cannot be kept down. But then the terrible Space-Bat-Angel-Dragon attacks Earth from outer space. Only the Iron Man can save us… `
        },
        {
          title: "Frankenstein",
          author: "Mary Shelley",
          date: 1818,
          cover: "frankenstein.png",
          description: `Navigating the Arctic, the captain of a ship rescues a man wandering near death across the ice caps. How the man got there reveals itself as a story of ambition, murder and revenge. As a young scientist, Victor Frankenstein pushed moral boundaries in order to cross the final frontier and create life. (This is an abridged version by Philip Pullman from 2003.)`
        },
        {
          title: "The Guardians",
          date: 1970,
          author: "John Christopher",
          cover: "theguardians.png",
          description: `In the divided England of the distant future, a recently-orphaned boy flees the sprawling area known as the Conurb for the serene world of the County where the people live a simpler existence. `
        },
        {
          title: "Mrs Frisby and the Rats of NIMH",
          titleDisplay: "Mrs Frisby and\nthe Rats of NIMH",
          author: "Robert C. O’Brien",
          date: 1971,
          cover: "mrsfrisbyandtheratsofnimh.png",
          description: `Time is running out for Mrs Frisby. She must move her family of mice before the farmer destroys their home. Help comes in the unexpected form of a group of mysterious, super-intelligent rats. `
        },

        {
          title: "The Earth Giant",
          author: "Melvin Burgess",
          date: 1971,
          cover: "theearthgiant.jpg",
          description: `A girl finds herself in telepathic communication with an extraterrestrial visitor that a storm has awakened from a 5000-year sleep. `
        },
        {
          title: "Woof!",
          date: 1986,
          author: "Allan Ahlberg",
          cover: "woof.png",
          description: `Eric is a perfectly ordinary boy. Perfectly ordinary that is, until the night when, in fifteen seconds flat, he turns into a dog! Eric and his best friend are determined to sniff out the truth - what makes an ordinary boy go ‘woof’?`
        },
        {
          title: "The Giver",
          author: "Lois Lowry",
          date: 1993,
          cover: "thegiver.jpg",
          description: `Jonas’s world is perfect. Everything in his Community is under control. There is no war, no hunger, no fear or pain. And there are no choices. Every person is assigned a role in the community, and when Jonas turns twelve he is singled out to receive special training.`
        },
        {
          title: "His Dark Materials",
          author: "Philip Pullman",
          date: 1995,
          cover: "hisdarkmaterials.png",
          description: `<em>Northern Lights</em> introduces Lyra, an orphan, who lives in a parallel universe in which science, theology and magic are entwined. Lyra's search for a kidnapped friend uncovers a sinister plot involving stolen children and turns into a quest to understand a mysterious phenomenon called Dust.`
        }
      ]
    },
    {
      name: "Real Life Line",
      textColor: "#000000",
      backgroundColor: "#FDBFE8",
      image: RealLifeImage,
      books: [
        {
          title: "Great Expectations",
          author: "Charles Dickens",
          date: 1860,
          cover: "greatexpectations.png",
          description: `Little orphan Pip scarcely imagines how a terrifying encounter with a convict on the lonely marshes will later transform his life. Pip is more troubled by his visits to strange old Miss Havisham - her decaying wedding dress and the house full of memories - and the beautiful girl Estella who makes him ashamed of his country manners and coarse hands. 
				`
        },
        {
          title: "A Christmas Carol",
          author: "Charles Dickens",
          date: 1843,
          cover: "achristmascarol.png",
          description: `Mr Scrooge is a squeezing, wrenching, grasping, scraping, clutching, miserable old man. Nobody stops him in the street to say a cheery hello; nobody would dare ask him for a favour. And I hope you'd never be so foolish as to wish him a 'Merry Christmas'! Scrooge doesn't believe in Christmas, charity, kindness - or ghosts.`
        },
        {
          title: "The Children of the New Forest",
          author: "Captain Fredrick Marryat",
          date: 1847,
          cover: "thechildrenofthenewforest.jpg",
          description: `Set in the time of the English Civil War and then the Commonwealth, the story follows the fortunes of the aristocratic four Beverley children who are orphaned during the war, and hide from their Roundhead oppressors in the shelter of the New Forest. There they learn to live off the land.
				`
        },
        {
          title: "Jane Eyre",
          author: "Charlotte Brontë",
          date: 1847,
          cover: "janeeyre.png",
          description: `Orphan, Jane Eyre, endures an unhappy childhood, hated by her aunt and cousins and then sent to comfortless Lowood School. But life there improves and Jane stays on as a teacher, though she still longs for love and friendship. `
        },
        {
          title: "The Family from One End Street",
          titleDisplay: "The Family from\nOne End Street",
          date: 1937,
          author: "Eve Garnett",
          cover: "thefamilyfromoneendstreet.png",
          description: `This is the story of everyday life in the big, happy Ruggles family who live in the small town of Otwell. Father is a dustman and Mother a washerwoman. Garnett wanted to support the people who lived in the poverty of East London by writing about their stories and way of life.`
        },
        {
          title: "The Silver Sword",
          author: "Ian Serrailier",
          date: 1956,
          cover: "thesilversword.png",
          description: `Set in Poland and Switzerland, four children struggle to survive and find their parents. Based on true accounts, this is a moving story of life during and after the Second World War.`
        },
        {
          title: "Walkabout",
          author: "James Vance Marshall",
          date: 1959,
          cover: "walkabout.png",
          description: `Mary and her young brother, Peter, are the only survivors of an aircrash in the middle of the Australian outback. Facing death from exhaustion and starvation, they meet an aboriginal boy who helps them to survive and guides them along their long journey. `
        },
        {
          interchange: {
            title: "The Indian in the Cupboard",
            line: "Fantasy & Magic Line"
          }
        },
        {
          title: "Grimms’ Fairy Tales",
          author: "Jakob and Wilhelm Grimm",
          date: 1819,
          cover: "brothersgrimm.png",
          description: `From the terrible lands of castles, vast lakes and deep forests, the brothers, Jakob and Wilhelm, spun a dark and magical web of stories, including: <em>The Frog Prince</em>, <em>Hansel and Grettel</em> and <em>Ashputtel</em>, the original Cinderella.`
        },
        {
          title: "The Adventures of Robin Hood",
          titleDisplay: "The Adventures\nof Robin Hood",
          author: "Howard Pyle",
          date: 1883,
          cover: "theadventuresofrobinhood.png",
          description: `Robin Hood, champion of the poor and oppressed, stands against the cruel power of Prince John and the brutal Sheriff of Nottingham. Taking refuge in the vast Sherwood Forest with his band of men, he remains determined to outwit his enemies.`
        },
        {
          interchange: { title: "The Three Musketeers", line: "Adventure Line" }
        },
        {
          title: "Smith",
          author: "Leon Garfield",
          date: 2014,
          cover: "smith.png",
          description: `London street urchin, Smith, is 12 years old and an experienced pick-pocket. One day on Ludgate Hill, he robs an old gentleman, and one minute later watches him silently murdered by two men. They chase Smith for the document he has stolen but does not understand.`
        },
        {
          title: "Underground to Canada",
          author: "Barbara Smucker",
          date: 1977,
          cover: "undergroundtocanada.jpg",
          description: `‘There's a place the slaves been whisperin' around called Canada. The law don't allow no slavery there. They say you follow the North Star, and when you step onto this land you are free …’ Two little girls dream of escaping slavery in North America. This novel is based on several true stories.`
        },
        {
          title: "The Runaways",
          author: "Ruth Thomas",
          date: 1987,
          cover: "therunaways.png",
          description: `Julia and Nathan are outsiders in class and in friendship groups. Their adventure is about to begin...`
        },
        {
          title: "The Arrival",
          author: "Shaun Tan",
          date: 2006,
          cover: "thearrival.jpg",
          description: `A story of migration in pictures. This raises questions about the importance of belonging to a place.`
        }
      ]
    },
    {
      name: "Scary and Horror Line",
      textColor: "#ffffff",
      backgroundColor: "#9B51E0",
      image: HorrorImage,
      books: [
        { interchange: { title: "Frankenstein", line: "Sci-Fi Line" } },
        {
          title: "The Children of Green Knowe",
          titleDisplay: "The Children\nof Green Knowe",
          date: 1954,
          author: "Lucy M. Boston",
          cover: "thechildrenofgreenknowe.png",
          description: `Tolly's great-grandmother wasn't a witch but both she and her old house, Green Knowe, were full of a very special kind of magic. There are other children living in the house - children who had been happy there centuries before. `
        },
        {
          title: "Tales of Edgar Allan Poe",
          titleDisplay: "Tales of \nEdgar Allan Poe",
          date: 1829,
          author: "Edgar Allan Poe",
          cover: "edgarallanpoe.png",
          description: `Many modern horror stories are based on characters and ideas from the poems and short stories of Poe. `
        },
        {
          interchange: {
            title: "Grimms’ Fairy Tales",
            line: "Real Life Line"
          }
        },
        {
          title: "The Hound of the Baskervilles",
          titleDisplay: "The Hound of the\nBaskervilles",
          author: "Sir Arthur Conan Doyle",
          date: 1901,
          cover: "thehoundofthebaskervilles.jpg",
          description: `The terrible spectacle of the beast, the fog of the moor and the discovery of a body. When Sir Charles Baskerville is found dead on the wild Devon moorland with the footprints of a giant hound nearby, the blame is placed on a family curse. `
        },
        {
          title: "The Wolves of Willoughby Chase",
          titleDisplay: "The Wolves of\nWilloughby Chase",
          author: "Joan Aitken",
          date: 1962,
          cover: "thewolvesofwilloughbychase.jpg",
          description: `1832 - a period of English History that never happened. Good King James III is on the throne and the country is ravaged by wolves which have migrated through the newly-opened Channel Tunnel.`
        },
        {
          interchange: {
            title: "The Ghost of Thomas Kempe",
            line: "Childhood Line"
          }
        },
        {
          title: "Stonestruck",
          author: "Helen Cresswell",
          date: 1995,
          cover: "stonestruck.png",
          description: `Jessica loses her house in the Blitz and is evacuated before the rest of her school to a huge Welsh castle with only the gardener and housekeeper for company. But she is not alone; the castle grounds are full of other mysterious presences.`
        },
        {
          title: "The Haunting",
          author: "Margaret Mahy",
          date: 1982,
          cover: "thehaunting.png",
          description: `Eight-year-old Barney has been haunted before. He thought it was something he'd just grow out of, like the imaginary friends his step-mother believes he has. But this time it's different. Footsteps follow him, there's a demanding voice barking orders and Barney begins to feel that sometimes his body is not his own at all.`
        },
        {
          title: "The Finders",
          author: "Nigel Hinton",
          date: 1994,
          cover: "thefinders.png",
          description: `Rosie posts a parcel for an old man and becomes spell-bound by the properties of the Djinn star locked in the parcel. She travels in time with the finders - two creatures only she can see - to search for the evil Djinn and banish him forever.`
        },
        {
          title: "Skellig",
          author: "David Almond",
          date: "1998",
          cover: "skellig.jpg",
          description: `When Michael moves to a new home, he discovers the power of celestial and spiritual beings who watch over him and his family. This book is inspired by and has references to the poetry of William Blake.`
        },
        {
          title: "The House of Rats",
          author: "Stephen Elboz",
          date: 2003,
          cover: "thehouseofrats.jpg",
          description: `Four children who are stranded in a mysterious gothic house. There are wolves prowling at the door and escape seems impossible, until they discover a whole new world under the house. `
        },
        {
          title: "The Graveyard Book",
          author: "Neil Gaiman",
          date: 2008,
          cover: "thegraveyardbook.png",
          description: `The story of the boy, Nobody ‘Bod’ Owens, who is adopted and raised by the supernatural occupants of a graveyard.`
        }, // THIS HAS BEEN SWAPPED!
        {
          title: "Canon Fire",
          author: "Various authors",
          date: 2008,
          cover: "canonfire.jpg",
          description: `Short stories from literary heritage paired with stories by contemporary writers.`
        }
      ]
    },
    {
      name: "Poetry Line",
      textColor: "#ffffff",
      backgroundColor: "#FF9213",
      image: PoetryImage,
      genres: [],
      books: [
        {
          title: "Beowulf (anonymous)",
          titleDisplay: "Beowulf",
          date: "795",
          genre: "Heroic Epic",
          cover: "beowulf.jpg",
          description: `The trials and exploits of the scandinavian hero, Beowulf, in the early 6th Century. `
        },
        {
          title: "Sir Gawain and the Green Knight (anonymous)",
          titleDisplay: "Sir Gawan and the Green Knight",
          date: "1400",
          genre: "Chivalric",
          cover: "sirgawainandthegreenknight.png",
          description: `This epic chronicles the  challenges between Sir Gawain, one of the bravest knights to sit at King Arthur’s Round Table, and the fearsome Green Knight.`
        },
        {
          title: "William Blake",
          date: "c. 1794",
          genre: "Romantic",
          cover: "williamblake.png",
          description: `18th century artist and poet, William Blake, wrote mystical and  spirit-filled verse. Best known for his masterpieces “Songs of Innocence” and “Songs of Experience”, his poems are often written in contrasting pairs such as The Lamb and The Tiger. `
        },
        {
          title: "Samuel Taylor Coleridge",
          date: "c. 1800",
          genre: "Romantic",
          cover: "samueltaylorcoleridge.png",
          description: `Coleridge is one of the first poets to use everyday language to express important ideas and events. His most famous works are the epic poems: <em>The Rime of the Ancient Mariner</em>, <em>Christabel</em> and <em>Kubla Khan.</em>`
        },
        {
          title: "William Wordsworth",
          date: "c. 1807",
          genre: "Romantic",
          cover: "williamwordsworth.png",
          description: `Wordsworth was great friends with Coleridge. Together they wrote Lyrical Ballads about the wonder and beauty of nature.  They started the Romantic movement in poetry and were also known as the Lake Poets.  Man’s powerful reaction to nature is shown in his well-known poem, <em>I Wandered Lonely as a Cloud</em>.`
        },
        {
          title: "Elizabeth Barrett Browning",
          date: "c. 1825",
          genre: "Victorian",
          cover: "elizabethbarrettbrowning.jpg",
          description: `Elizabeth Barrett Browning wrote the famous love poems of the Victorian age. <em>Sonnets from the Portuguese</em> published in 1850, famously begins <em>How do I love thee? Let me count the ways.</em>`
        },
        {
          title: "Robert Browning",
          date: "c. 1842",
          genre: "Victorian",
          cover: "robertbrowning.png",
          description: `<em>The Pied Piper of Hamelin</em> is Robert Browning’s best known poem for children. His poems explore the darkest of minds, question historical facts and are rich in visual imagery. `
        },
        {
          title: "Alfred Lord Tennyson",
          date: "c. 1851",
          genre: "Victorian",
          cover: "alfredlordtennyson.jpg",
          description: `Tennyson wrote about the glories of England but also the worries of the Victorian Age. He was incredibly popular with Victorians. His poems include <em>The Charge of the Light Brigade</em> - glorifying war - and <em>The Lady of Shalott</em> - celebrating the legends of England. `
        },
        {
          title: "Henry Wadsworth Longfellow",
          date: "c. 1858",
          genre: "Victorian",
          cover: "henrywadsworthlongfellow.png",
          description: `Longfellow was one of the popular <em>Fireside Poets</em> writing in the US at this time. He wrote idyllic ballads about New England life. He was writing at the same time as Coleridge, Wordsworth and Twain.`
        },
        {
          title: "Walt Whitman",
          date: "c. 1860",
          genre: "Victorian",
          cover: "waltwhitman.jpg",
          description: `From the depths of the sea to the far reaches of the cosmos, from the songs of America’s workers to the plight of America’s slaves, Whitman’s poems chronicled the New American age.`
        },
        {
          title: "Lewis Carroll",
          date: "c. 1871",
          genre: "Victorian",
          cover: "lewiscarroll.png",
          description: `With fantastic characters and enchanting language, Lewis Carroll created magical wonderlands that children would love to visit and portals to disappear through. `
        },
        {
          title: "Christina Rossetti",
          date: "1875",
          genre: "Victorian",
          cover: "christinarossetti.png",
          description: `Rossetti’s lyrical ballads explore Victorian childhood, nature and devotion. `
        },
        {
          title: "Edward Lear",
          date: "c. 1877",
          genre: "Victorian",
          cover: "edwardlear.jpg",
          description: `Lear popularised the genre of literary nonsense. His poems swing from the serious to the absurd and the best-known of these is <em>The Owl and the Pussycat</em>.`
        },
        {
          title: "Robert Louis Stevenson",
          date: "c. 1885",
          genre: "Victorian",
          cover: "robertlouisstevenson.jpg",
          description: `Stevenson wrote narrative poetry about exciting adventures and voyages he dreamt of. He spent much of his children confined to his sick bed yet his imagination was vivid and wild.`
        },
        {
          title: "Emily Dickinson",
          date: "c. 1891",
          genre: "Victorian",
          cover: "emilydickinson.png",
          description: `The beauty and magic of the everyday world through the eyes of Emily Dickinson, one of America's best-loved poets. Flowers, birds, sunrises, sunsets, the moon, and even her own existence take on surprising meanings.
				`
        },
        {
          title: "Hilaire Belloc",
          date: "c. 1907",
          genre: "Edwardian",
          cover: "hilariebelloc.png",
          description: `If you are you prone to telling tales or running away then ignore these poems at your peril or you might suffer the same fate as Matilda <em>Who told lies</em> or Jim <em>Who ran away from his nurse and was eaten by a Lion</em>! Belloc developed the styles of satire and parody in his verse. `
        },
        {
          title: "Rudyard Kipling",
          date: "c. 1910",
          genre: "Edwardian",
          cover: "rudyardkipling.png",
          description: `Kipling wrote his poems from the 1880s to the 1930s spanning the late Victorian Age to the Modern Age. His poems reflect the times with the decline of the British Empire and the destruction post World War One.`
        },
        {
          title: "Harold Munro",
          date: "c. 1913",
          genre: "Edwardian",
          cover: "haroldmunro.jpg",
          description: `Munro’s poetry explores morality and he was at the start of the Modernist movement. His best-known poem for children is <em>Overheard on a Saltmarsh</em>.`
        },
        {
          title: "Robert Frost",
          date: "c. 1916",
          genre: "Edwardian",
          cover: "robertfrost.jpg",
          description: `Use all your senses—not just your eyes—when you read Robert Frost’s remarkable poems. Your own world will quickly melt away as Frost draws you into winter wonderlands, forests and fields. He is one of America’s best-loved poets. `
        },
        {
          title: "Walter de la Mare",
          date: "c. 1925",
          genre: "Modern",
          cover: "walterdelamare.png",
          description: `De la Mare wrote in a variety of structures and forms. His poems have a dream-like quality and explore fantasy and the supernatural worlds. `
        },
        {
          title: "Alfred Noyes",
          date: "c. 1952",
          genre: "Modern",
          cover: "alfrednoyes.png",
          description: `Noyes wrote thrilling and passionate narrative poetry. His romantic influences were from earlier poets, Wordsworth and Tennyson. His most famous poem is, ‘The Highwayman’.`
        },
        {
          title: "T. S. Eliot",
          date: "c. 1939",
          genre: "Modern",
          cover: "tseliot.jpg",
          description: `These lovable cat poems were written by T. S. Eliot for his godchildren.  The collection inspired the musical <em>Cats!</em> and features Macavity, Mr Mistoffelees and Growltiger amongst others.`
        },
        {
          title: "Langston Hughes",
          date: "c. 1930",
          genre: "Modern",
          cover: "langstonhughes.png",
          description: `Hughes wrote in a time when Black citizens were fighting for equality in America. He wrote about this struggle using powerful and visual language. He developed a new rhythm coining the term - <em>Jazz Poetry</em>. His poetry is also a reaction to an earlier poet, Longfellow, who used racial stereotyping in his poetry.`
        },
        {
          title: "James Reeves",
          date: "c. 1957",
          genre: "Modern",
          cover: "jamesreeves.jpg",
          description: `Reeves’s poetry is well known for its use of poetic technique: stylised with onomatopoeia, metaphor, rhythm and personification. His best-known poem is, ‘The Sea’.`
        },
        {
          title: "Dylan Thomas",
          date: "c. 1952",
          genre: "Modern",
          cover: "dylanthomas.jpg",
          description: `Thomas is known for his imaginative language and vivid imagery. His poetry full of emotional power and longing.  One of his most famous poems about childhood is ‘Fern Hill’.`
        },
        {
          title: "Ted Hughes",
          date: "c. 1960",
          genre: "Modern",
          cover: "tedhughes.png",
          description: `Hughes’s poetry often features animals: their beauty and fight for survival. His word choice is tense, powerful and has pronounced sounds. One of his most well known poems is, ‘The Thought-Fox’.`
        },
        {
          title: "Carol Ann Duffy",
          date: "Present",
          genre: "Modern",
          cover: "carolannduffy.png",
          description: `Carol Ann Duffy's writing bubbles with wit and humour, intelligence and affection. Among her characters are: the Loch Ness Monster's husband, the oldest girl in the world and a herd of cows on a shopping trip to Manchester. Duffy held the post of Poet Laureate from 2009 to 2019.`
        }
      ]
    }
  ],
  interchanges: [],

  pedestrianInterchanges: [
    {
      from: { line: "Childhood Line", title: "A Child’s Christmas in Wales" },
      to: { line: "Poetry Line", title: "Dylan Thomas" }
    }
  ]
};

export default bookData;
