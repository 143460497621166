import React from "react";
import styled, { keyframes } from "styled-components";
import buttonCloseActive from "../images/Button-close-active.svg";
import buttonCloseIdle from "../images/Button-close-idle.svg";

const animationOpen = keyframes`
	0% {opacity: 0; transform: scale3d(0,0,1);}
	100% {opacity: 1; transform: scale3d(1,1,1);}
`;

const showup = keyframes`
	0% {
		opacity: 0;
	}
	99.99% {
		opacity: 0;
	}
	100% {
		opacity: 1
	}
`;

const PopUpWrapper = styled.div`
  --width: 800;
  position: fixed;
  width: 800px;
  height: 600px;
  left: calc(50% - 400px);
  top: 100px;
  top: 60px;
  z-index: 10;
  font-weight: 400;
  --imagePadding: 48px;
  --insidePadding: 12px;
  --halfPadding: 12px;
  --widePadding: 24px;
  --borderColor: #e0e0e0;
  box-sizing: border-box;
  &.othercontent.howitworks {
    width: 600px;
    --width: 600;
    left: calc(50% - 300px);
    & nav {
      right: calc(50% - 300px + 12px);
    }
  }
  &.othercontent.traindepot {
    --width: 800;
    width: 800px;
    left: calc(50% - 400px);
    overflow-y: hidden;
    box-sizing: border-box;
    & nav {
      right: calc(50% - 400px + 12px);
    }
    & nav + div {
      box-sizing: border-box;
      padding-bottom: 64px;
			overflow-y: hidden;
      & #othercontentdiv {
        max-height: 548px;
        box-sizing: border-box;
				overflow-y: hidden;
        & #understandingwrapper {
					max-height: 450px;
					padding-right: 0;
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      top: 0;
      margin: 0 !important;
      width: 100%;
      border-radius: 0;
      overflow-y: scroll !important;
      height: 100%;
      & nav {
        top: 10px !important;
        right: 10px !important;
      }
      & nav + div {
        height: 100%;
        border-radius: 0;
        & #othercontentdiv {
          max-height: unset !important;
          & #understandingwrapper {
						padding: 16px !important;
            max-height: calc(100vh - 110px) !important;
						max-width: calc(100vw - 66px);
						overflow-y: hidden;
					
            & > div {
              max-height: calc(100vh - 200px);
							overflow-y: scroll;
            }
          }
        }
      }
    }
  }
  &.othercontent {
    max-width: 100vw;
    @media screen and (max-width: 767px) {
      left: 0 !important;
      border-radius: 0 !important;
      height: 100% !important;
      & nav {
        right: 12px !important;
        & + div {
          border-radius: 0 !important;
          padding: 0;
          height: 100%;
          & div {
            max-height: unset;
            padding-bottom: 48px;
            & div {
              max-height: calc(100vh - 90px) !important;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      margin: 0;
      border-radius: 0;
      overflow-y: scroll;
      width: 100% !important;
      height: 100%;
      & > nav {
        top: 10px;
        right: 10px;
      }
      & > div {
        height: 100%;
        border-radius: 0;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    width: calc(100vw - 80px);
    margin-left: 40px;
    margin-right: 40px;
    bottom: 40px;
    height: unset;
    bottom: unset;
    width: 625px;
    margin: 0;
    left: calc(calc(100vw - 625px) / 2);
    right: calc(calc(100vw - 625px) / 2);
  }
  @media screen and (max-width: 767px) {
    width: calc(100vw - 40px);
    top: 60px;
    left: unset;
    right: unset;
    margin-left: 20px;
    margin-right: 20px;
    bottom: 20px;
    height: calc(100vh - 80px); /* turn this off for regular popup! */
    height: unset;
  }
  @media screen and (max-width: 414px),
    screen and (max-height: 812px) and (max-width: 414px) {
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 100%;
    border-radius: 0;
    overflow-y: scroll;
    height: 100%;
    background-color: white;
    & nav {
      top: 5px !important;
      right: 5px !important;
    }
    & > div {
      box-shadow: none;
      height: unset;
      border-radius: 0;
      & > div > div {
        margin-left: 0;
        margin-bottom: 0;
        max-width: unset;
        width: 100%;
        & a {
          width: 100%;
          & img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    & tr {
      display: flex;
      flex-direction: column;
      width: 100%;
      & + tr td + td {
        border-top: none !important;
      }
      & td + td {
        padding-left: 0 !important;
      }
    }
  }
  @media screen and (max-height: 414px) and (max-width: 812px) {
    /* NOTE: LEGEND DOESN'T REALLY WORK LIKE THIS! */
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 100%;
    border-radius: 0;
    overflow-y: scroll;
    height: 100%;
    & nav {
      top: 5px !important;
      right: 5px !important;
    }
    & > div {
      height: unset;
      border-radius: 0;
    }
  }
  & nav {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: fixed;
    right: calc(50% - 400px + 12px);
    top: 110px;
    top: 70px;
    z-index: 2;
    animation: ${showup} 0.4s;
    & span {
      width: 28px;
      height: 28px;
      background-image: url(${buttonCloseIdle});
      &:hover {
        background-image: url(${buttonCloseActive});
      }
    }
    @media screen and (max-width: 1023px) and (min-width: 768px) {
      right: calc(calc(calc(100vw - 625px) / 2) + 12px);
    }
    @media screen and (max-width: 767px) {
      top: 70px;
      right: 40px;
      & span {
        justify-content: center;
      }
    }
    @media screen and (max-height: 375px) and (max-width: 812px) {
      top: 10px;
      right: 10px;
    }
    @media screen and (max-width: 375px) and (max-height: 812px) {
      top: 8px;
      right: 8px;
    }
    & span {
      color: #3f3f3f;
      user-select: none;
      cursor: pointer;
      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
`;

const PopUpDiv = styled.div`
  color: black;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  padding: 0;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.5s;
  animation-name: ${animationOpen};
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  @media screen and (max-width: 767px) {
    height: calc(100vh - 80px); /* turn this off for regular popup! */
    height: unset;
    padding-top: 30px;
    padding-bottom: 40px; /* turn this off for regular popup! */
    padding-bottom: 0;
  }
`;

const PopUpScrollDiv = styled.div`
  display: flex;
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  /* @media screen and (max-height: 767px) {
    padding: 8px;
    position: relative;
    top: 20px;
  } */
  @media screen and (max-width: 767px) {
    padding: 8px;
    overflow-y: auto;
    height: 100%;
  }
`;

const PopUpImageContainer = styled.div`
  user-select: none;
  flex: 1;
  width: 376px;
  padding: 0;
  border: 1px solid var(--borderColor);
  border-color: transparent;
  border-radius: 4px;
  margin: var(--insidePadding) 0 var(--insidePadding) var(--insidePadding);
  margin-top: 38px;
  box-sizing: border-box;
  align-self: stretch;
  display: flex;
  @media screen and (max-width: 1023px) {
    margin-top: 0;
    max-width: calc(100vw - 80px);
    padding: var(--insidePadding) 0 0 0;
    padding: 0;
    align-self: center;
    width: unset;
    height: unset;
  }
  & img {
    box-sizing: border-box;
    width: 280px;
    height: auto;
    display: block;
    @media screen and (max-width: 1023px) {
      height: 250px;
      width: auto;
    }
  }
`;

const PopUpRightSide = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  padding: var(--insidePadding);
  user-select: none;
  transition: 0.5s;
  padding-top: 38px;
  height: 470px; /* this is based on tennyson */
  @media screen and (max-width: 1023px) {
    flex: unset;
    height: initial;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-width: 600px;
  }
  @media screen and (min-width: 515px) and (max-width: 767px) {
    min-width: 100%;
  }
  & ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    padding-bottom: var(--insidePadding);
  }
  /* @media screen and (max-height: 767px) {
    padding: 0;
  } */
`;

const BookBox = styled.div`
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  padding: var(--insidePadding);
  /* margin-top: var(--insidePadding); */
  transition: 0.5s;
  flex: 2;
  @media screen and (max-width: 1023px) {
    flex: unset;
    margin-top: 0;
  }
  & h1 {
    margin: 0;
    font-size: 20px;
    user-select: text;
    & a {
      color: black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  & h2 {
    margin: 0;
    margin-top: 6px;
    margin-bottom: var(--widePadding);
    font-weight: normal;
    color: #b0b0b0;
    font-size: 16px;
    user-select: text;
  }
  & ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    margin-bottom: var(--widePadding);
  }
  & p {
    margin: 0;
    margin-top: var(--widePadding);
    font-size: 15px;
    line-height: 21px;
    user-select: text;
    color: #333333;
  }
  & ul + p {
    @media screen and (min-width: 415px) and (max-width: 767px) {
      height: 126px;
    }
  }
`;

const LineBox = styled.li`
  color: ${props => props.color || "black"};
  padding: 6px 8px;
  border-radius: 4px;
  margin-right: var(--halfPadding);
  font-weight: normal;
  display: inline-flex;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: ${props => props.backgroundColor || "white"};
  & img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

const ConnectionWrapper = styled.div`
  display: flex;
  margin-top: var(--insidePadding);
  /* margin-top: var(--widePadding); */
  user-select: none;
  @media screen and (max-width: 1023px) {
    margin-top: var(--insidePadding);
  }
  @media screen and (max-width: 525px) {
    flex-direction: column;
    justify-content: center;
  }
  & div {
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    padding: var(--insidePadding);
    height: 100%;
    max-width: 50%;
    @media screen and (max-width: 525px) {
      max-width: 100%;
    }
    & h3 {
      margin: 0;
      user-select: none;
    }
    & ul {
      margin: 0;
    }
  }
`;

const ConnectionFromBox = styled.div`
  box-sizing: border-box;
  flex: 1;
  & + div {
    margin-left: var(--insidePadding);
    @media screen and (max-width: 525px) {
      margin-left: unset;
      margin-top: var(--insidePadding);
    }
  }
`;

const ConnectionToBox = styled.div`
  box-sizing: border-box;
  flex: 1;
`;

const Connection = styled.li`
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  user-select: none;
  font-weight: normal;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${props => props.color || "#2A2A2A"};
  padding: 6px 8px;
  border-radius: 4px;
  margin-top: var(--halfPadding);
  background-color: ${props => props.backgroundColor || "white"};
`;

const OtherContentDiv = styled.div`
  padding: 32px 16px;
  margin-top: 32px;
  & > div {
    border-radius: 4px;
    border: 1px solid var(--borderColor);
    padding: 32px;
    & h2 {
      margin-top: 0;
    }
    & p {
      margin: 0;
      font-size: 16px;
      line-height: 27px;
      & + p {
        margin-top: 13.5px;
      }
    }
  }
`;

const UnderstandingDiv = styled.div`
  overflow-y: scroll;
  max-height: 435px;
  padding-right: 32px;
  @media screen and (max-width: 800px) {
    padding-right: 0;
  }
`;

const UnderstandingTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  padding: 0;
  margin: 0;
  & tr {
    & td {
      padding: 4px 0;
      vertical-align: top;
      & a {
        display: inline-block;
        line-height: 27px;
        text-decoration: none;
        color: black;
      }
      & + td {
        padding-left: 16px;
      }
    }
    & p {
      margin: 0 !important;
      font-size: 16px;
    }
    & + tr td {
      border-top: 1px solid #e4edf9;
    }
  }
`;

const PopUpWindow = ({
  node,
  fromNodes,
  toNodes,
  closePopUp,
  colorData,
  otherContent
}) => {
  const cleanLineLinks = nodeList => {
    const outList = JSON.parse(JSON.stringify(nodeList));

    // 1: first remove all the lines that are not part of the line you're on

    for (let i = 0; i < outList.length; i++) {
      const filteredList = outList[i].line.filter(
        x => node.line.indexOf(x) > -1
      );
      outList[i].line = filteredList;
    }

    // 2. then check against single lines?
    // (this shouldn't usually need to come into play)

    const [singleLines] = nodeList
      .map(x => x.line.filter(y => node.line.indexOf(y) > -1))
      .filter(z => z.length < 2);

    for (let i = 0; i < outList.length; i++) {
      // console.log(outList[i].line);
      if (outList[i].line.length > 1) {
        let redoneLine = outList[i].line.filter(
          x => singleLines.indexOf(x) < 0
        );
        // console.log(outList[i].line, redoneLine);
        outList[i].line = redoneLine;
      }
    }
    return outList;
  };

  const cleanedFromNodes = fromNodes ? cleanLineLinks(fromNodes) : null;
  const cleanedToNodes = toNodes ? cleanLineLinks(toNodes) : null;

  return (
    <PopUpWrapper
      className={
        otherContent
          ? otherContent === 1
            ? "othercontent howitworks"
            : "othercontent traindepot"
          : null
      }
    >
      <nav>
        <span
          onClick={() => {
            closePopUp(null);
          }}
        />
      </nav>
      <PopUpDiv>
        {otherContent ? (
          <OtherContentDiv id="othercontentdiv">
            {otherContent === 1 ? (
              <div>
                <h2>How it works</h2>
                <p style={{ marginBottom: "16px" }}>
                  <strong>1.</strong> Choose a book to read from the Tanglin
                  Reading Express. Each book represents a different time and
                  genre from the English Literary Heritage alongside significant
                  texts from around the world.
                </p>
                <p style={{ marginBottom: "16px" }}>
                  <strong>2.</strong> When you finish reading a book, complete
                  one of the tasks under Train Depot. You will receive a stamp
                  on the train map in your passport.
                </p>
                <p>
                  <strong>3.</strong> You should complete three books from one
                  line to complete the level. As you complete the various
                  levels, you will understand the appeal and importance over
                  time of the Literary Canon.
                </p>
              </div>
            ) : (
              <div id="understandingwrapper">
                <h2>Show your Understanding</h2>
                <UnderstandingDiv id="understandingdiv">
                  <UnderstandingTable>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSc60rywh1m40JCtUu1k9buMIA2PHQCi22W1-IWTAeMi22IjIQ/viewform?usp=sf_link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Setting</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSc60rywh1m40JCtUu1k9buMIA2PHQCi22W1-IWTAeMi22IjIQ/viewform?usp=sf_link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>Make a map of a place from your book.</p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeJJxDQOrTNiYnaZdSVRA7GnRhc32ekEZSYmWpY_4_ry-EilQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Time</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeJJxDQOrTNiYnaZdSVRA7GnRhc32ekEZSYmWpY_4_ry-EilQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Write a timeline of five events for one character.
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeaSUrMYzBVYvTX8eOjsxaAFDRXOh3qqmYSxsUYc-BUkb0eXQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Author</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeaSUrMYzBVYvTX8eOjsxaAFDRXOh3qqmYSxsUYc-BUkb0eXQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Write three questions you would like to ask the
                              author.
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfT-gByfF3QBeL1GaIDYPaPZ1S9tzX-WtqfmKxeeR3Rr_BWbw/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>The Past</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfT-gByfF3QBeL1GaIDYPaPZ1S9tzX-WtqfmKxeeR3Rr_BWbw/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Write three sentences about what you think
                              happened before the start of the story.
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSff6ezjHPViuh6CZXzD_13UXEDwZ3eTI1A1xqJlXU1PZFLFqQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Comparisons</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSff6ezjHPViuh6CZXzD_13UXEDwZ3eTI1A1xqJlXU1PZFLFqQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Compare your book with another book you have read.
                            </p>
                            <p>This book is similar to ____ because...</p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfhpRGK1JHY8J0EfM_jpnupCEdQIKmAVBYHaqhmsiZFiWTKqw/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Artwork</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfhpRGK1JHY8J0EfM_jpnupCEdQIKmAVBYHaqhmsiZFiWTKqw/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Draw the face of one character and label it with
                              three adjectives.
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSd3fd0D7hGtZc6ZPfHR_S7ECLYOV1BfcASmcWlLJ3DNu5ypoA/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Title</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSd3fd0D7hGtZc6ZPfHR_S7ECLYOV1BfcASmcWlLJ3DNu5ypoA/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Pretend you are the author and explain why you
                              chose this title for your book.
                            </p>
                            <p>I chose the title ____ because…</p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSdlIuS2ZjtK6pBrwR-cTTZdqnqMs1U6bMU5KRxM0hn2XOS5hw/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Drama</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSdlIuS2ZjtK6pBrwR-cTTZdqnqMs1U6bMU5KRxM0hn2XOS5hw/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              You are a movie director, choose an actor to play
                              the lead character of your book in a movie.
                            </p>
                            <p>I would cast ____ in the role of____.</p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSch0cMyckRMlpEBS0c5yk9Ab-smmrn8Vu9jj9dTm4siWR9nxg/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Predictions</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSch0cMyckRMlpEBS0c5yk9Ab-smmrn8Vu9jj9dTm4siWR9nxg/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Predict what will happen to one of the characters
                              the year after the book ends. Write five
                              sentences.
                            </p>
                            <p>The following year, ____ will be….</p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSdXORfzLxsoFi2gBdC-mg0zVl61C1_QCuX0Yz1HnUR5f60wWA/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Recommendations</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSdXORfzLxsoFi2gBdC-mg0zVl61C1_QCuX0Yz1HnUR5f60wWA/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>Who would enjoy this book?</p>
                            <p>Why?</p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScpfnWbk9_S3lwEyDpci75msdoVNvwqLGW3ykOa3qnJIJ06-w/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Interview</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScpfnWbk9_S3lwEyDpci75msdoVNvwqLGW3ykOa3qnJIJ06-w/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Make up 3 interview questions (with answers) for
                              the main character.
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeV7U3oDalkkZVZlodD35KMF-NFkMjWQ-ae3xckta5DB2sBRg/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Vocabulary</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeV7U3oDalkkZVZlodD35KMF-NFkMjWQ-ae3xckta5DB2sBRg/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Choose five interesting words and write
                              definitions to make a mini dictionary.
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeFfm943YrUdEBZv5xgzBirYM5ZciTmmM2ywoup0dFUhqZwpg/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Literary Qualities</strong>
                          </a>
                        </td>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeFfm943YrUdEBZv5xgzBirYM5ZciTmmM2ywoup0dFUhqZwpg/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>Who tells this story?</p>
                            <p>
                              Who else would make a good narrator of this book?
                            </p>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfyWg8sgJk4A7Qg34v9lC9eke7EWHVuq3NaFBzQfaj0dZZnsQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>Review</strong>
                          </a>
                        </td>
                        <td style={{ paddingBottom: 32 }}>
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfyWg8sgJk4A7Qg34v9lC9eke7EWHVuq3NaFBzQfaj0dZZnsQ/viewform"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              Write a review of this book in five sentences.
                            </p>
                            <p>
                              Rate it out of five stars.
                              <br />
                              &nbsp;
                              <br />
                              &nbsp;
                            </p>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </UnderstandingTable>
                </UnderstandingDiv>
              </div>
            )}
          </OtherContentDiv>
        ) : (
          <PopUpScrollDiv>
            {node.cover ? (
              <PopUpImageContainer>
                <a
                  href={`https://tts.concordinfiniti.com/library/search/title?searchSiteId=1224843&searchTerm=${encodeURIComponent(
                    node.title
                  )}&sort=0`}
                  target={"_blank"}
                >
                  <img src={`/images/${node.cover}`} alt={node.title} />
                </a>
              </PopUpImageContainer>
            ) : null}
            <PopUpRightSide>
              <BookBox>
                <h1>
                  <a
                    href={`https://tts.concordinfiniti.com/library/search/title?searchSiteId=1224843&searchTerm=${encodeURIComponent(
                      node.title
                    )}&sort=0`}
                    target={"_blank"}
                  >
                    {node.title}
                  </a>
                </h1>
                {node.author && node.date ? (
                  <h2>
                    Written by {node.author} in {node.date}
                  </h2>
                ) : null}
                {node.genre ? (
                  <p>
                    <strong>Genre:</strong> {node.genre}
                  </p>
                ) : null}
                <ul>
                  {node.line.map((x, key) => (
                    <LineBox
                      key={key}
                      color={colorData[x].color}
                      backgroundColor={colorData[x].backgroundColor}
                    >
                      <img src={colorData[x].image} alt={colorData[x].title} />
                      {x}
                    </LineBox>
                  ))}
                </ul>
                {node.description ? (
                  <p dangerouslySetInnerHTML={{ __html: node.description }} />
                ) : null}
              </BookBox>
              <ConnectionWrapper>
                {cleanedFromNodes.length > 0 ? (
                  <ConnectionFromBox>
                    <h3>Previous</h3>
                    <ul>
                      {cleanedFromNodes.map((x, index) => (
                        <Connection
                          key={index}
                          onClick={() => closePopUp(x.id)}
                          color={
                            x.line[0]
                              ? colorData[x.line[0]].color
                              : colorData[node.line[0]].color
                          }
                          backgroundColor={
                            x.line[0]
                              ? colorData[x.line[0]].backgroundColor
                              : colorData[node.line[0]].backgroundColor
                          }
                        >
                          {x.titleDisplay || x.title}
                        </Connection>
                      ))}
                    </ul>
                  </ConnectionFromBox>
                ) : null}
                {cleanedToNodes.length > 0 ? (
                  <ConnectionToBox>
                    <h3>Next</h3>
                    <ul>
                      {cleanedToNodes.map((x, index) => (
                        <Connection
                          key={index}
                          onClick={() => closePopUp(x.id)}
                          color={
                            x.line[0]
                              ? colorData[x.line[0]].color
                              : colorData[node.line[0]].color
                          }
                          backgroundColor={
                            x.line[0]
                              ? colorData[x.line[0]].backgroundColor
                              : colorData[node.line[0]].backgroundColor
                          }
                        >
                          {x.titleDisplay || x.title}
                        </Connection>
                      ))}
                    </ul>
                  </ConnectionToBox>
                ) : null}
              </ConnectionWrapper>
            </PopUpRightSide>
          </PopUpScrollDiv>
        )}
      </PopUpDiv>
    </PopUpWrapper>
  );
};

export default PopUpWindow;
