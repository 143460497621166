import React from "react";
import styled from "styled-components";

import MapSVG from "./components/MapSVG";
// import Header from "./components/Header.js";
import PopUpScrim from "./components/PopUpScrim";
import PopUpWindow from "./components/PopUpWindow.js";
import MapKey from "./components/MapKey.js";
import buildGraphData from "./components/buildgraphdata.js";

import bookData from "./assets/bookdata";

const { nodesToBooks, colorData } = buildGraphData(bookData);

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4edf9;
  overflow-x: hidden;
  overflow-y: hidden;
`;

// const TanglinLogo = styled.img`
//   position: fixed;
//   right: 20px;
//   bottom: 20px;
//   height: 100px;
//   width: 100px;
//   box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
//   @media screen and (max-width: 767px) {
//     display: none;
//   }
// `;

function App() {
  // const [zoom, setZoom] = React.useState('100%');
  const [popUpValue, setPopUpValue] = React.useState(null);
  const [recenter, setRecenter] = React.useState(true);
  const [otherContent, setOtherContent] = React.useState(null);

  // preload the images

  React.useEffect(() => {
    console.log("Preloading images!");
    bookData.lines.forEach(line => {
      line.books.forEach(book => {
        if (book.cover) {
          // console.log(book.cover);
          const img = new Image();
          img.src = `/images/${book.cover}`;
        }
      });
    });
  }, []);

  const handleClosePopUp = x => {
    // console.log("in handle close pop up!");
    setOtherContent(null);
    setPopUpValue(
      x !== null
        ? {
            node: nodesToBooks[x],
            fromNodes: nodesToBooks[x].from.map(y => nodesToBooks[y]),
            toNodes: nodesToBooks[x].to.map(y => nodesToBooks[y])
          }
        : null
    );
  };

  React.useEffect(() => {
    setRecenter(false);
  }, [setRecenter]);

  return (
    <AppContainer className="App">
      {/*<Header
        clicked={() => {
          setRecenter(true);
          setTimeout(() => {
            setRecenter(false);
          }, 500);
        }}
      />*/}
      <MapSVG
        recenter={recenter}
        openStation={x =>
          setPopUpValue({
            node: nodesToBooks[x],
            fromNodes: nodesToBooks[x].from.map(y => nodesToBooks[y]),
            toNodes: nodesToBooks[x].to.map(y => nodesToBooks[y])
          })
        }
      />
      <MapKey
        openOther={x => {
          setRecenter(false);
          setOtherContent(x);
        }}
        clicked={() => {
          // setRecenter(true);
          // setTimeout(() => {
          //   setRecenter(false);
          // }, 500);
        }}
      />
      <PopUpScrim
        shown={popUpValue || otherContent}
        closePopUp={handleClosePopUp}
      />
      {popUpValue !== null || otherContent !== null ? (
        <PopUpWindow
          node={popUpValue ? popUpValue.node : null}
          fromNodes={popUpValue ? popUpValue.fromNodes : null}
          toNodes={popUpValue ? popUpValue.toNodes : null}
          closePopUp={handleClosePopUp}
          colorData={colorData}
          otherContent={otherContent}
        />
      ) : null}
      {/*<TanglinLogo src={"/images/tanglinlogo.png"} />*/}
    </AppContainer>
  );
}

export default App;
