import React from "react";
import styled from "styled-components";

const PopUpScrimDiv = styled.div`
  pointer-events: ${props => (props.shown ? "initial" : "none")};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.5s;
  opacity: ${props => (props.shown ? 1 : 0)};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

const PopUpScrim = ({ shown, closePopUp }) => (
  <PopUpScrimDiv
    shown={shown}
    onClick={() => {
      closePopUp(null);
    }}
  />
);
export default PopUpScrim;
