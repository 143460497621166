import React from "react";
import styled from "styled-components";
import LegendPNG from "../images/Legend-update.png";
// import LegendMinimized from "../images/Button-tablet-idle.svg";
import LegendMinimizedActive from "../images/Button-tablet-active.svg";

// TODO: Should this be more interactive? It's not really doing anything now.

const MapKeyDiv = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  user-select: none;
  justify-content: center;
  &:not(.minimized) {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  }
  /* height: 590px; */
  & img {
    width: 260px;
    height: 560px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    transition: 0.25s;
    &.minimized {
      width: 90px;
      height: unset;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
      &:hover {
        box-shadow: none;
      }
    }
  }
  & svg {
    height: 528px;
    position: relative;
    left: -149px;
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    top: 20px;
  }
  @media screen and (max-width: 767px) {
    &:not(.minimized) {
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.5);
      align-items: center;
      justify-content: flex-start;
      overflow-y: scroll;
      padding: 40px 0;
      box-shadow: none;
      height: 100%;
      & img {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      }
      & div {
        bottom: unset;
        top: 555px;
      }
    }
    /* display: none; */
  }
`;

const HowItWorksDiv = styled.div`
  transition: 0.25s;
  position: absolute;
  bottom: 18px;
  width: 77px;
  height: 28px;
  left: 20px;
  cursor: pointer;
  display: inline-block;
  background: #d5e0ee;
  border-radius: 5px;
  cursor: pointer;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 10.5px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #333333;
  &.minimized {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    /* display: none; */
  }
  &:hover {
    background: #aabcd4;
  }
  @media screen and (max-width: 767px) {
    left: unset;
    right: calc(50vw + 36px);
    bottom: 60px;
  }
`;
const TrainDepotDiv = styled.div`
  transition: 0.25s;
  position: absolute;
  bottom: 18px;
  right: 20px;
  width: 77px;
  height: 28px;
  background: #d5e0ee;
  border-radius: 5px;
  cursor: pointer;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 10.5px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #333333;
  opacity: 1;
  &.minimized {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    /* display: none; */
  }
  &:hover {
    background: #aabcd4;
  }
  @media screen and (max-width: 767px) {
    right: unset;
    left: calc(50vw + 36px);
    bottom: 60px;
  }
`;

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    // console.log("firing!");
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const MapKey = ({ clicked, openOther }) => {
  const size = useWindowSize();
  const [minimized, setMinimized] = React.useState(
    size.width <= 768 ? true : false
  );

  return (
    <MapKeyDiv
      className={minimized ? "minimized" : ""}
      onClick={() => {
        if (size.width <= 768) {
          setMinimized(!minimized);
        }
        // clicked(true);
      }}
    >
      <img
        alt="Legend"
        src={minimized ? LegendMinimizedActive : LegendPNG}
        // onMouseOver={
        //   minimized ? e => (e.currentTarget.src = LegendMinimizedActive) : null
        // }
        // onMouseLeave={
        //   minimized ? e => (e.currentTarget.src = LegendMinimized) : null
        // }
        onClick={() => {
          setMinimized(!minimized);
          // clicked(true);
        }}
        className={minimized ? "minimized" : ""}
      />
      <HowItWorksDiv
        className={minimized ? "minimized" : ""}
        onClick={() => {
          openOther(1);
          // setMinimized(false);
        }}
      >
        How it works
      </HowItWorksDiv>
      <TrainDepotDiv
        className={minimized ? "minimized" : ""}
        onClick={() => {
          openOther(2);
          // setMinimized(false);
        }}
      >
        Train Depot
      </TrainDepotDiv>
    </MapKeyDiv>
  );
};

export default MapKey;
